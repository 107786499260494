import 'bootstrap5';
import './styles/app.scss';
import './vendors/slick/slick.css';
import './vendors/slick/slick-theme.css';
import './vendors/slick/slick';
//import Sortable from 'sortablejs';

"use strict";

function ready(fn) {
    if (document.readyState != 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

const isNumber = function(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
    }
    return true;
};

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function removeCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    let newCookies = [];
    for(let i = 0; i <ca.length; i++) {
        if (ca[i].indexOf(name) === -1) {
            newCookies.push(ca[i]);
        }
    }
    document.cookie = newCookies.join(';');
}

const popupCenter = ({url, title, w, h}) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(url, title,
      `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `
    )

    if (window.focus) newWindow.focus();
}

function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    var items = location.search.substr(1).split("&");
    for (var index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
}

const scrollClick = function(e) {
    e.preventDefault();
    document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth',
        block: 'start'
    });
};

const ageField = document.getElementById('age');
const agePresent = document.getElementById('agePresent');
const ageAction = function() {
    agePresent.value = ageField.value;
    let tempVal = (ageField.value-ageField.min)/(ageField.max-ageField.min)*100;
    if(tempVal>50) tempVal-=1;
    ageField.style.setProperty('--form-range-track-bg', 'linear-gradient(to right, #018f1f 0%, #018f1f ' + tempVal + '%, #f4f4f4 ' + tempVal + '%, #f4f4f4 100%)');
    if(ageField.value >= 21) {
        document.querySelector('.age-range.age-range-2').classList.add('active');
    } else {
        document.querySelector('.age-range.age-range-2').classList.remove('active');
    }
    if(ageField.value >= 28) {
        document.querySelector('.age-range.age-range-3').classList.add('active');
    } else {
        document.querySelector('.age-range.age-range-3').classList.remove('active');
    }
    if(ageField.value >= 41) {
        document.querySelector('.age-range.age-range-4').classList.add('active');
    } else {
        document.querySelector('.age-range.age-range-4').classList.remove('active');
    }
};

ready(function() {
    const scrollLinks = document.querySelectorAll('.scroll-link');
    Array.prototype.forEach.call(scrollLinks, function(el, i) {
        el.addEventListener('click', scrollClick);
    });
    if(ageField && agePresent) {
        ageField.addEventListener('change', ageAction);
        ageField.addEventListener('input', ageAction);
        agePresent.addEventListener('blur', function() {
            let val = parseInt(this.value);
            if(val<18) val = 18;
            if(val>100) val = 100;
            ageField.value = val;
            ageAction();
        });
    }
    const goToStep2 = document.getElementById('goToStep2');
    if(goToStep2) goToStep2.addEventListener('click', function() {
        document.querySelector('.stepForm.step1').classList.add('d-none');
        document.querySelector('.stepForm.step2').classList.remove('d-none');
        document.querySelector('.step.step2').classList.add('active');
        document.getElementById('stepNr').innerText = '2';
        document.querySelector('#formLabel .step1').classList.add('d-none');
        document.querySelector('#formLabel .step2').classList.remove('d-none');
        document.querySelector('#quiz').scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    });
    const backToStep1 = document.getElementById('backToStep1');
    if(backToStep1) backToStep1.addEventListener('click', function() {
        document.querySelector('.stepForm.step2').classList.add('d-none');
        document.querySelector('.stepForm.step1').classList.remove('d-none');
        document.querySelector('.step.step2').classList.remove('active');
        document.getElementById('stepNr').innerText = '1';
        document.querySelector('#formLabel .step2').classList.add('d-none');
        document.querySelector('#formLabel .step1').classList.remove('d-none');
        document.querySelector('#quiz').scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    });
    const goToStep3 = document.getElementById('goToStep3');
    if(goToStep3) goToStep3.addEventListener('click', function() {
        document.querySelector('.stepForm.step2').classList.add('d-none');
        document.querySelector('.stepForm.step3').classList.remove('d-none');
        document.querySelector('.step.step3').classList.add('active');
        document.getElementById('stepNr').innerText = '3';
        document.querySelector('#formLabel .step2').classList.add('d-none');
        document.querySelector('#formLabel .step3').classList.remove('d-none');
        document.querySelector('#quiz').scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    });
    const backToStep2 = document.getElementById('backToStep2');
    if(backToStep2) backToStep2.addEventListener('click', function() {
        document.querySelector('.stepForm.step3').classList.add('d-none');
        document.querySelector('.stepForm.step2').classList.remove('d-none');
        document.querySelector('.step.step3').classList.remove('active');
        document.getElementById('stepNr').innerText = '2';
        document.querySelector('#formLabel .step3').classList.add('d-none');
        document.querySelector('#formLabel .step2').classList.remove('d-none');
        document.querySelector('#quiz').scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    });
    const goToStep4 = document.getElementById('goToStep4');
    if(goToStep4) goToStep4.addEventListener('click', function() {
        let resultsUrl = '#';
        if(ageField.value < 27) {
            resultsUrl = 'wynik';
        } else if(ageField.value < 40) {
            resultsUrl = 'wynik2';
        } else {
            resultsUrl = 'wynik3';
        }
        const form = document.getElementById('questionaireForm');
        let fields = {};
        var formData = new FormData(form);
        for(var pair of formData.entries()) {
            fields[pair[0]] = pair[1];
        }
        if(fields && Object.keys(fields).length > 0) {
            resultsUrl += "?d="+btoa(JSON.stringify(fields));
        }
        document.getElementById('goToResults').setAttribute('href', resultsUrl);
        setCookie('userResult', resultsUrl, 365);
        grecaptcha.ready(function () {
            grecaptcha.execute(RECAPTCHA_KEY, {}).then(function (token) {
                formData.append('g-recaptcha-response', token);
                $.ajax({
                    method: "POST",
                    url: form.getAttribute('action'),
                    data: formData,
                    dataType: "json",
                    processData: false,
                    contentType: false
                })
                .done(function(response) {
                    if(response.status == 'ok') {
                        setTimeout(function() {
                            window.location = resultsUrl;
                        }, 3000);
                    } else if(response.status == 'error') {
                    }
                })
                .fail(function(jqXHR, reason) {
                    console.log("Request failed1: " + reason);
                });
            }).catch(function(reason) {
                console.log("Request failed2: " + reason);
            });
        });
        document.querySelector('.stepForm.step3').classList.add('d-none');
        document.querySelector('.stepForm.step4').classList.remove('d-none');
        document.querySelector('#titles').classList.add('d-none');
        document.querySelector('#steps').classList.add('d-none');
        document.querySelector('#quiz').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
    });
    const sortIt = document.getElementById('sortIt');
    const sortAnswer = document.getElementById('sortAnswer');
    // if(sortIt && sortAnswer) {
    //     var sortable = Sortable.create(sortIt, {
    //         dataIdAttr: 'data-sentenceid',
    //         onEnd: function() {
    //             sortAnswer.value = sortable.toArray().join(',');
    //         }
    //     });
    // }
    const sortUp = function(e) {
        e.preventDefault();
        let start_pos = e.data.pos;
        let end_pos = e.data.pos - 1;
        let targetCopy = sortIt.querySelector('.row[data-sentenceid="'+end_pos+'"] .sentence').innerHTML;
        sortIt.querySelector('.row[data-sentenceid="'+end_pos+'"] .sentence').innerHTML = sortIt.querySelector('.row[data-sentenceid="'+start_pos+'"] .sentence').innerHTML;
        sortIt.querySelector('.row[data-sentenceid="'+start_pos+'"] .sentence').innerHTML = targetCopy;
        let output = sortAnswer.value.split(",");
        [output[start_pos-1], output[end_pos-1]] = [output[end_pos-1], output[start_pos-1]];
        sortAnswer.value = output.join(",");
    };
    const sortDown = function(e) {
        e.preventDefault();
        let start_pos = e.data.pos;
        let end_pos = e.data.pos + 1;
        let targetCopy = sortIt.querySelector('.row[data-sentenceid="'+end_pos+'"] .sentence').innerHTML;
        sortIt.querySelector('.row[data-sentenceid="'+end_pos+'"] .sentence').innerHTML = sortIt.querySelector('.row[data-sentenceid="'+start_pos+'"] .sentence').innerHTML;
        sortIt.querySelector('.row[data-sentenceid="'+start_pos+'"] .sentence').innerHTML = targetCopy;
        let output = sortAnswer.value.split(",");
        [output[start_pos-1], output[end_pos-1]] = [output[end_pos-1], output[start_pos-1]];
        sortAnswer.value = output.join(",");
    };
    if(sortIt && sortAnswer) {
        const sortElems = sortIt.querySelectorAll('.row');
        Array.prototype.forEach.call(sortElems, function(el, i) {
            $(el).on('click', '.sort.up', { pos: parseInt(el.getAttribute('data-sentenceid')) }, sortUp);
            $(el).on('click', '.sort.down', { pos: parseInt(el.getAttribute('data-sentenceid')) }, sortDown);
        });
    }
    $('.numberonly-field').each(function() {
        $(this).on('keypress', function(e) {
            return isNumber(e);
        }).on('paste', function (e) {
            if(e.originalEvent.clipboardData.getData('Text').match(/[^\d]/)) {
                e.preventDefault();
                var newVal = $(this).val()+""+e.originalEvent.clipboardData.getData('Text').replace(/[^0-9]/g, "");
                $(this).val(newVal.substring(0,$(this).attr('maxlength')));
            }
        });
    });
    let dataParam = findGetParameter('d');
    if(dataParam !== null) {
        var dataArray = JSON.parse(atob(dataParam));
        if(dataArray && Object.keys(dataArray).length > 0) {
            if(dataArray.hasOwnProperty('sortAnswer') && dataArray.sortAnswer.length) {
                dataArray.sortAnswer = dataArray.sortAnswer.split(",");
                if(typeof dataArray.checkbox1 === 'undefined' || dataArray.checkbox1 != "1") {
                    document.getElementById('add1').classList.add('d-none');
                }
                if(typeof dataArray.checkbox2 === 'undefined' || dataArray.checkbox2 != "1") {
                    document.getElementById('add2').classList.add('d-none');
                    if(typeof dataArray.checkbox3 !== 'undefined' && dataArray.checkbox3 == "1") {
                        document.getElementById('add3').classList.add('d-none');
                        document.getElementById('add3standalone').classList.remove('d-none');
                    }
                }
                if(typeof dataArray.checkbox3 === 'undefined' || dataArray.checkbox3 != "1") {
                    document.getElementById('add3').classList.add('d-none');
                    if(typeof dataArray.checkbox2 !== 'undefined' && dataArray.checkbox2 == "1") {
                        document.getElementById('add2').classList.add('d-none');
                        document.getElementById('add2standalone').classList.remove('d-none');
                    }
                }
                if(typeof dataArray.checkbox4 === 'undefined' || dataArray.checkbox4 != "1") {
                    document.getElementById('add4').classList.add('d-none');
                }
                if(typeof dataArray.checkbox5 === 'undefined' || dataArray.checkbox5 != "1") {
                    document.getElementById('add5').classList.add('d-none');
                }
                if(dataArray.sortAnswer.length && typeof dataArray.sortAnswer[0] !== 'undefined') {
                    //kolejność zasad
                    let tempHTML = '';
                    let tempElem = '';
                    let tempIndex = 0;
                    let rulesArr = $('.rule[href^="#rule"]');
                    let collapseArr = $('.collapse[id^="rule"]');
                    let togglersArr = $('.toggler[href^="#rule"]');
                    for(const sa of dataArray.sortAnswer) {
                        tempIndex++;
                        tempElem = $(rulesArr[sa-1]);
                        tempElem.find('em span').text(tempIndex);
                        tempElem.find('.no').text("0"+tempIndex);
                        tempHTML += tempElem[0].outerHTML;
                        tempHTML += $(collapseArr[sa-1])[0].outerHTML;
                        tempElem = $(togglersArr[sa-1]);
                        tempElem.find('.no').text("0"+tempIndex);
                        tempHTML += tempElem[0].outerHTML;
                    }
                    $('.rule[href^="#rule"], .collapse[id^="rule"], .toggler[href^="#rule"]').remove();
                    $('#insertAfter').after(tempHTML);
                    const rule01 = document.getElementById('rule01');
                    if(rule01) rule01.addEventListener('show.bs.collapse', event => {
                        document.querySelector('.rule[href="#rule01"]').scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                        });
                    });
                    if(rule01) rule01.addEventListener('hide.bs.collapse', event => {
                        document.querySelector('.rule[href="#rule01"]').scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                        });
                    });
                    const rule02 = document.getElementById('rule02');
                    if(rule02) rule02.addEventListener('show.bs.collapse', event => {
                        document.querySelector('.rule[href="#rule02"]').scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                        });
                    });
                    if(rule02) rule02.addEventListener('hide.bs.collapse', event => {
                        document.querySelector('.rule[href="#rule02"]').scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                        });
                    });
                    const rule03 = document.getElementById('rule03');
                    if(rule03) rule03.addEventListener('show.bs.collapse', event => {
                        document.querySelector('.rule[href="#rule03"]').scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                        });
                    });
                    if(rule03) rule03.addEventListener('hide.bs.collapse', event => {
                        document.querySelector('.rule[href="#rule03"]').scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                        });
                    });
                    const rule04 = document.getElementById('rule04');
                    if(rule04) rule04.addEventListener('show.bs.collapse', event => {
                        document.querySelector('.rule[href="#rule04"]').scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                        });
                    });
                    if(rule04) rule04.addEventListener('hide.bs.collapse', event => {
                        document.querySelector('.rule[href="#rule04"]').scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                        });
                    });
                    const rule05 = document.getElementById('rule05');
                    if(rule05) rule05.addEventListener('show.bs.collapse', event => {
                        document.querySelector('.rule[href="#rule05"]').scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                        });
                    });
                    if(rule05) rule05.addEventListener('hide.bs.collapse', event => {
                        document.querySelector('.rule[href="#rule05"]').scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                        });
                    });
                    const rule06 = document.getElementById('rule06');
                    if(rule06) rule06.addEventListener('show.bs.collapse', event => {
                        document.querySelector('.rule[href="#rule06"]').scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                        });
                    });
                    if(rule06) rule06.addEventListener('hide.bs.collapse', event => {
                        document.querySelector('.rule[href="#rule06"]').scrollIntoView({
                            behavior: 'smooth',
                            block: 'start'
                        });
                    });
                    //tekst nagłówkowy
                    document.getElementById('txtheading'+dataArray.sortAnswer[0]).classList.remove('d-none');
                    //obrazki nagłówkowe
                    if(dataArray.hasOwnProperty('gender') && dataArray.gender.length && dataArray.hasOwnProperty('age') && dataArray.age.length) {
                        let imgName = 'img/';
                        if(dataArray.gender == 'man') {
                          imgName += 'm-';
                        } else {
                          imgName += 'k-';
                        }
                        if(parseInt(dataArray.age) >= 18 && parseInt(dataArray.age) < 28) {
                          imgName += '21-';
                        } else if(parseInt(dataArray.age) >= 28 && parseInt(dataArray.age) < 41) {
                          imgName += '28-';
                        } else if(parseInt(dataArray.age) >= 41) {
                          imgName += '40-';
                        }
                        switch(parseInt(dataArray.sortAnswer[0])) {
                          case 1:
                            imgName += 'dba-o-bezpieczenstwo-finansow-w-internecie';
                          break;
                          case 2:
                            imgName += 'swiadomie-wydaje-pieniadze';
                          break;
                          case 3:
                            imgName += 'zapewnia-sobie-poduszke-finansowa';
                          break;
                          case 4:
                            imgName += 'zabezpiecza-siebie-i-bliskich';
                          break;
                          case 5:
                            imgName += 'pozycza-z-glowa';
                          break;
                          case 6:
                            imgName += 'inwesuje-na-przyszlosc';
                          break;
                        }
                        imgName += '.jpg';
                        const vp = document.querySelectorAll('.variable-photo');
                        for(const el of vp) {
                          el.style.backgroundImage = "url('"+imgName+"')";
                        }
                    }
                }
            }
        }
    }
    const sharer = document.getElementById('sharer');
    if(sharer) sharer.addEventListener('click', function(e) {
        e.preventDefault();
        popupCenter({url: this.href+window.location.protocol+'//'+window.location.hostname, title: 'Udostępnij', w: 600, h: 300});
        return false;
    });
    let userResultRestored = getCookie('userResult');
    if(ageField && userResultRestored.length) { //if 'home' and cookie present
        window.location = userResultRestored;
    }
    const resetFormBtns = document.querySelectorAll('.reset-form');
    Array.prototype.forEach.call(resetFormBtns, function(el, i) {
        el.addEventListener('click', function(e) {
            e.preventDefault();
            setCookie('userResult', '', 0);
            window.location = "/";
        });
    });

    $('.clear-result').on('click', function () {
        setCookie('userResult', '', 0);
    });
});
